<style
  lang="postcss"
  scoped
>

  .pay-confirm {
    display: block;
    background: var(--base-color);
    color: #fff;
    text-align: center;
    width: calc(100% - 0.32rem);
    padding: 0.08rem 0;
    font-size: 0.14rem;
    border-radius: 1rem;
    margin: 0 auto 0.1rem;
    height: 0.34rem;
    line-height: 0.18;
    box-sizing: border-box;
    border: none;
  }

  .haiguan {
    font-size: 0.1rem;
    color: var(--base-color);
    padding: 0.08rem;
  }

  .captcha {
    padding: 0.08rem 1rem 0.08rem 0.08rem;
    color: #aaa;

    & span {
      margin-right: 0.08rem;
    }

    & .border {
      padding: 0.06rem 0.08rem;

      & input {
        width: 100%;
      }
    }
  }

  .time-note {
    color: var(--base-color);
    margin: 0 0.04rem -0.03rem;
  }

  .first-order {
    text-align: center;

    & >>> .warning {
      color: var(--base-color);
    }
  }
</style>
<template>
  <pay-layout>
    <div slot="top">确认付款</div>
    <div slot="money">
      <div
        class="flex-center-center"
        v-if="pay_info.pay_second && pay_info.pay_second > 0"
      >
        <span class="time-note">{{ pay_info.pay_second_front_text || "赶紧支付" }}</span>
        <count-down :second="pay_info.pay_second"/>
        <span class="time-note">{{ pay_info.pay_second_after_text || "后订单将被系统取消!" }}</span>
      </div>
      <money
        :pay_amount="pay_info.pay_amount"
        :payed_amount="pay_info.payed_amount"
        :payed_name="pay_info.thirdPoint"
        :member_available_pd="pay_info.member_available_pd"
        :is_use_yue="currIsUseYue"
      />
      <div
        class="flex-center-center"
        v-if="pay_info.firstOrderNote"
      >
        <p
          class="first-order"
          v-html="pay_info.firstOrderNote"
        ></p>
      </div>
    </div>
    <div slot="center">
      <Assets
        :key="11"
        :is-use-assets="currIsUseJifen"
        :assets="pay_info.member_available_dd"
        :pay-amount="pay_info.pay_amount"
        @step="nextStep('jifenConfig')"
      >
        <div
          class="flex-center-center"
          slot="label"
        >
          <span class="iconfont icon-jifen"/>
          <span>积分抵扣</span>
        </div>
      </Assets>
      <Assets
        :key="22"
        :is-use-assets="currIsUseYue"
        :assets="pay_info.member_available_pd"
        :pay-amount="pay_info.is_haiguan ? pay_info.pay_haiguan_amount : pay_info.pay_amount"
        @step="nextStep('yueConfig')"
        v-if="!pay_info.payed_amount && !doNotUsePdPay"
      >
        <div
          class="flex-center-center"
          slot="label"
        >
          <span class="iconfont icon-yue"/>
          <span>余额支付</span>
        </div>
      </Assets>
      <Assets
        :key="33"
        :assets="pay_info.thirdPoint.balance / pay_info.thirdPoint.radio"
        :isUseAssets="currIsUseThirdPoint"
        :pay-amount="pay_info.pay_amount"
        @step="nextStep('thirdPointConfig')"
        v-if="pay_info.thirdPoint && !pay_info.payed_amount"
      >
        <div
          class="flex-center-center"
          slot="label"
        >
          <span class="iconfont icon-jifen"/>
          <span>{{ pay_info.thirdPoint.name }}</span>
        </div>
        <div
          style="color: #ccc;font-size: smaller"
          slot="desc"
        >
          {{ pay_info.thirdPoint.desc || `（${pay_info.thirdPoint.radio + pay_info.thirdPoint.unit} = 1元）` }}
        </div>
      </Assets>
      <p
        class="haiguan"
        v-if="pay_info.is_haiguan&&currIsUseYue"
      >{{ pay_info.is_haiguan }}
      </p>
      <div
        class="flex-left-center captcha"
        v-if="pay_info.is_need_captcha_input === 1"
      >
        <span>验&nbsp;&nbsp;证&nbsp;&nbsp;码</span>
        <div class="border btn-radius flex-1">
          <input
            type="text"
            :placeholder="pay_info.captcha_placeholder || '请输入'"
            v-model="captcha"
          />
        </div>
      </div>
      <payment
        :otherText="currIsUseYue || currIsUseJifen"
        :payInfo="pay_info"
        v-model="payType"
        @extra="paymentExtra"
        v-if="isShowPayList"
      />
    </div>
    <mt-button
      class="pay-confirm"
      slot="bottom"
      @click="confirmPay"
    >立即付款
    </mt-button>
  </pay-layout>
</template>
<script>
  import PayLayout from "./Layout"
  import Money from "./Money"
  import { Button, Toast } from "mint-ui"
  import Assets from "./Assets"
  import Payment from "./Payment"
  import { getKey, IS_MINIPROGRAM, siteConfig } from "@/config"
  import CountDown from "@/components/CountDown"

  const { qrCodeDepoit = false, noUseWxMiniPay = false, noFilterPayList = false } = siteConfig

  export default {
    name: "MyScene1",
    components: {
      CountDown,
      Payment,
      Assets,
      PayLayout,
      Money,
      [Button.name]: Button
    },
    props: {
      paySn: {
        type: String,
        required: true
      },
      isUseJifen: {
        type: Boolean,
        required: true,
        default: false
      },
      isUseYue: {
        type: Boolean,
        required: true,
        default: false
      },
      isUseThirdPoint: {
        type: Boolean,
        required: true,
        default: false
      },
      isShow: {
        type: Boolean,
        required: true,
        default: false
      },
      pwd: {
        type: String,
        required: true,
        default: ""
      },
      doNotUsePdPay: {
        type: Boolean,
        default: false
      },
      virtual: {
        type: Boolean,
        default: false
      },
      immediately: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        pay_info: {
          payment_list: undefined,
          member_available_pd: undefined,
          payed_amount: undefined,
          member_paypwd: false,
          pay_amount: undefined,
          is_haiguan: undefined,
          pay_haiguan_amount: undefined,
          thirdPoint: undefined,
          pay_second: undefined,
          pay_second_front_text: undefined,
          pay_second_after_text: undefined,
          firstOrderNote: ""
        },
        payType: "",
        currIsUseJifen: false,
        currIsUseYue: false,
        currIsUseThirdPoint: false,
        extra: {}, // 第三方支付扩展数据
        miniProgram: false,
        captcha: ""
      }
    },
    computed: {
      // 是否显示第三方支付
      isShowPayList () {
        const { thirdPoint: { balance, radio = 1 } = {} } = this.pay_info
        // 如果使用余额
        if (this.isUseYue && this.pay_info.member_available_pd >= this.pay_info.pay_amount) {
          return false
        }
        // 如果使用第三方积分
        if (this.currIsUseThirdPoint && balance / radio >= this.pay_info.pay_amount) {
          return false
        }
        // 如果是海关商品
        return !this.pay_info.is_haiguan

        // return !(this.currIsUseYue && this.pay_info.member_available_pd >= this.pay_info.pay_amount) ||
        //   !(this.currIsUseThirdPoint && balance * 1 > this.pay_info.pay_amount) ||
        //   this.pay_info.is_haiguan
      }
    },
    watch: {
      isUseJifen (newVal) {
        this.currIsUseJifen = newVal
      },
      isUseYue (newVal) {
        this.currIsUseYue = newVal
      },
      isUseThirdPoint (newVal) {
        this.currIsUseThirdPoint = newVal
      },
      isShow (newVal) {
        if (newVal === true) {
          this.toPay()
        }
      },
      pwd (newVal) {
        if (newVal.length === 6) {
          this.pay()
        }
      },
      immediately (newVal) {
        if (newVal) {
          this.toPay().then(() => {
            this.pay()
          })
        }
      }
    },
    methods: {
      toPay () {
        let params = this.virtual ? {
          act: "member_vr_buy",
          op: "pay"
        } : {
          act: "member_buy",
          op: "pay"
        }
        return this.$http.post("", this.postData({
          pay_sn: this.paySn
        }), {
          params
        }).then(res => {
          const { datas: { pay_info } } = res
          Object.keys(pay_info).map(key => {
            if (typeof pay_info[key] === "string" && /^\d+\.\d{2}$/.test(pay_info[key])) {
              pay_info[key] = Number(pay_info[key])
            }
          })
          let {
            payment_list = [],
            payed_amount = 0, // 默认已支付0
            member_available_dd = 0, // 默认用户积分0
            member_available_pd, // 账号余额
            defaultCardNo, // 就参卡
            thirdPoint = {} // 第三方积分抵扣
          } = pay_info

          if (!noFilterPayList) {
            payment_list = payment_list.filter(val => {
              return this.miniProgram ? val.payment_code === "wx_applet" : val.payment_code !== "wx_applet"
            })
          }

          if (!this.$route.query.unioncode && !pay_info.showlzf) {
            payment_list = payment_list.filter(val => val.payment_code !== "lzf2")
          }

          Object.assign(this.pay_info, { ...pay_info, payment_list })

          const firstPayment = payment_list[0]
          // 默认选择第一个支付方式
          this.payType = firstPayment && firstPayment.payment_code
          // 已支付的金额
          this.pay_info.payed_amount = payed_amount
          // 积分
          this.pay_info.member_available_dd = member_available_dd
          // 默认是否使用积分、余额、第三方积分
          this.valChange({
            isUseJifen: !!member_available_dd, // 积分
            isUseYue: !!member_available_pd && !payed_amount, // 是否使用余额支付
            isUseThirdPoint: !isNaN(thirdPoint.balance * 1) && !payed_amount, // 是否使用第三方积分支付
            payType: this.payType // 支付方式
          })
          // 默认卡号
          this.extra.card_no = defaultCardNo
        })
      },
      nextStep (name) {
        this.$emit("step", { type: "add", name })
      },
      valChange (val) {
        this.$emit("valChange", val)
      },
      paymentExtra (options) {
        Object.assign(this.extra, options)
      },
      confirmPay () {
        const currPayInfo = this.pay_info.payment_list.filter(val => val.payment_code === this.payType)[0]
        // 新兴铸管参数
        const isNeedPwd = currPayInfo["is_need_pwd"] === "1"
        const disableReset = currPayInfo["disable_reset"] === "1"
        const isNeedBindCard = currPayInfo["is_need_bind_card"] === 1
        // 是否需要铸馆帮卡
        if (isNeedBindCard) {
          this.$emit("step", { type: "add", name: "bindCard" })
          window.needBindCardInfo = currPayInfo
          return
        }
        // 第三方积分支付配置信息
        const { thirdPoint: { is_need_pwd, disable_reset, is_check_pwd } = {} } = this.pay_info

        if (this.isUseYue || isNeedPwd || (this.currIsUseThirdPoint && is_need_pwd === "1")) {
          // 礼如意不需要密码
          if (qrCodeDepoit) {
            this.pay(123456)
            return
          }
          // 是否禁用重置密码 -- 第三方支付
          if (disableReset || disable_reset === "1") {
            this.$emit("step", { type: "add", name: "inputPwd" })
            setTimeout(() => {
              window.eventBus.$emit("inputPwdEvent", {
                disableReset: true,
                checkPwd: is_check_pwd === "1"
              })
            }, 100)
            return
          }
          // 判断是否设置了密码
          if (this.pay_info.member_paypwd) {
            this.$emit("step", { type: "add", name: "inputPwd" })
          } else {
            this.$emit("step", { type: "add", name: "setPwd" })
          }
          // 余额支付需要重置参数
          setTimeout(() => {
            window.eventBus.$emit("inputPwdEvent", {
              disableReset: false,
              checkPwd: true
            })
          }, 100)
        } else {
          this.pay()
        }
      },
      pay (pwd) {
        // 小程序支付
        if (this.miniProgram && this.isShowPayList && this.payType === "wx_applet") {
          const keys = getKey().split("").reverse().join("")
          const psn = this.paySn
          const payment_code = this.payType
          const password = this.pwd || pwd
          const pd_pay = Number(!!password)
          window["wx"].miniProgram.navigateTo({
            url: `/pages/pay/index?key=${keys}&psn=${psn}&payment_code=${payment_code}&pd_pay=${pd_pay}&password=${password}`
          })
          return
        }
        let params = {
          act: "member_payment",
          op: this.virtual ? "vr_pay_new" : "pay_new",
          pay_sn: this.paySn,
          payment_code: this.payType,
          password: this.pwd || pwd,
          pd_pay: Number(this.currIsUseYue),
          dd_pay: Number(this.currIsUseJifen),
          th_pd_pay: Number(this.currIsUseThirdPoint),
          key: getKey(),
          captcha: this.captcha,
          // kwtarget: 'blank', // 孩子王app打开新webview
          ...this.extra
        }

        if (this.payType && this.paySn) {
          if (this.toPayFlag) {
            return
          }
          this.toPayFlag = true
          let payUrl = this.$http.defaults.baseURL
          let reqUrl = [payUrl, this.queryString(params)].join("?")
          // 是否为zuzupay
          if (this.payType === "zuzupay") {
            this.$http.get("", {
              params
            }).then(res => {
              let url
              if (process.env.NODE_ENV === "development") {
                url = "http://192.168.1.115:8001"
              } else {
                url = "http://zuzu.giftgine.cn"
              }
              const currHref = window.location.href.split("?")[0]
              const temp = {
                redirect: currHref.replace("make-order", "order"),
                ...res["datas"]
              }
              location.href = `${url}/dashboard/pay?${this.queryString(temp)}`
              this.toPayFlag = false
            }).catch(() => {
              this.toPayFlag = false
            })
          } else {
            if (this.pay_info.is_need_captcha_input === 1) {
              if (!this.captcha) {
                Toast({
                  position: "bottom",
                  message: "验证码不能为空"
                })
                this.toPayFlag = false
                return
              }
            }
            const link = reqUrl + (localStorage.getItem("openType") === "1" ? "&openType=1" : "")

            // 小程序cpay支付
            if (IS_MINIPROGRAM && this.payType === "cpay") {
              this.miniProgramPay(link)
            } else {
              location.href = link
            }
            setTimeout(() => {
              this.toPayFlag = false
            }, 300)
          }
        }
      },
      queryString (params) {
        const arr = []
        Object.keys(params).forEach(key => {
          if (params[key] !== undefined) {
            arr.push([key, encodeURIComponent(params[key])].join("="))
          }
        })
        return arr.join("&")
      },
      miniProgramPay (link) {
        // 清除iframe
        if (this.cpayIframe) {
          document.body.removeChild(this.cpayIframe)
        }

        // 在iframe中打开
        this.cpayIframe = document.createElement("iframe")
        this.cpayIframe.style.display = "none"
        this.cpayIframe.src = link
        document.body.appendChild(this.cpayIframe)

        // 3秒后移除iframe
        if (this.cpayIframe) {
          clearTimeout(this.cpayTimer)
        }
        this.cpayTimer = setTimeout(() => {
          document.body.removeChild(this.cpayIframe)
          this.cpayTimer = null
          this.cpayIframe = null
        }, 3000)

        // 轮询支付结果
        this.miniProgramPayStatusLoop()
      },
      miniProgramPayStatusLoop () {
        if (this.cpayStatusTimer) {
          clearTimeout(this.cpayStatusTimer)
        }
        this.cpayStatusTimer = setTimeout(() => {
          if (!this.isShow) {
            clearTimeout(this.cpayStatusTimer)
            this.cpayStatusTimer = null
            return
          }
          this.$http.get("", {
            params: {
              act: "hapi",
              op: "getOrderState",
              pay_sn: this.paySn,
              type: this.virtual ? "v" : undefined
            },
            loading: false
          }).then(res => {
            if (res.datas.error === 1) {
              clearTimeout(this.cpayStatusTimer)
              this.cpayStatusTimer = null
              if (this.$route.path.indexOf("/order") > -1) {
                location.reload()
              } else {
                this.$router.push(this.virtual ? "/order-vr" : "/order")
              }
            } else if (res.datas === 0) {
              this.miniProgramPayStatusLoop()
            }
          })
        }, 1200)
      }
    },
    created () {
      // 输入密码正确
      window.eventBus.$on("pwdIsOk", pwd => {
        this.pay(pwd)
      })
      window.eventBus.$on("restPwdIsOk", pwd => {
        this.pay_info.member_paypwd = true
        this.pay(pwd)
      })
      // 小程序环境
      this.miniProgram = IS_MINIPROGRAM && !noUseWxMiniPay
      // 绑卡成功 刷新数据 from BindCard.vue
      window.eventBus.$on("bindCardOk", () => {
        this.toPay()
      })
    },
    destroyed () {
      if (this.cpayStatusTimer) {
        clearTimeout(this.cpayStatusTimer)
      }
    }
  }
</script>
