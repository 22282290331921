export function transformSort (sortName) {
  const query = {}
  switch (sortName) {
    case "sale_desc":
      query.key = 1
      query.order = 2
      break
    case "price_asc":
      query.key = 3
      query.order = 1
      break
    case "price_desc":
      query.key = 3
      query.order = 2
      break
    case "date_asc":
      query.key = 4
      break
    case "hot_desc":
      query.key = 2
      query.order = 2
      break
    case 'random':
      query.sortName = true
      break
    default:
      break
  }
  return query
}

export function transformQuery ({sortName, ...temp}) {
  const  searchOptions = {}
  searchOptions.b_id = temp.b_id || temp.brandId
  searchOptions.gc_id = temp.gc_id || temp.gcId || temp.categoryId
  searchOptions.price_from = temp.price_from || temp.priceFrom
  searchOptions.price_to = temp.price_to || temp.priceTo
  searchOptions.keyword = temp.keyword || temp.words
  searchOptions.xianshi = temp.xianshi ? 1 : undefined
  searchOptions.groupbuy = temp.groupbuy ? 1 : undefined
  searchOptions.key = temp.key
  searchOptions.order = temp.order
  return {
    ...searchOptions,
    ...transformSort(sortName)
  }
}