const imgSize = function (link, size = 'n0') {
  if (!link) {
    return ''
  }
  // 爱库存
  if (link.indexOf('.aikucun.') !== -1) {
    switch (size.toLowerCase()) {
      case 'n0':
        return link
      case 'n1':
        return link + '?imageMogr2/thumbnail/!50p/strip/quality/80/size-limit/50k!'
      case 'n2':
        return link + '?imageMogr2/thumbnail/!30p/strip/quality/80/size-limit/30k!'
    }
  }
  // node 服务器图片
  if (link.indexOf('/public/') === 0) {
    return link
  }
  // 第三方商品
  if (/image(\d+)?\.giftgine\.cn/.test(link) || /\/data\/upload/.test(link)) {
    let thumb
    switch (size.toLowerCase()) {
      case 'n0':
        thumb = ''
        break
      case 'n1':
        thumb = '_360'
        break
      case 'n2':
        thumb = '_240'
        break
    }
    return link.replace(/_(240|360|800|1280)\./, '.').replace(/\.(jpg|jpeg|png)$/, thumb + '.$1').replace(/http(s)?:/, '')
  }
  // 苏宁
  if (link.indexOf('suning.cn') !== -1) {
    link = link.replace(/http(s)?:/, '')
    switch (size.toLowerCase()) {
      case 'n0':
        return link
      case 'n1':
        return link.replace(/_\d+w_\d+h/, '_350w_350h').replace(/http(s)?:/, '')
      case 'n2':
        return link.replace(/_\d+w_\d+h/, '_240w_240h').replace(/http(s)?:/, '')
    }
  }
  // 京东
  let reg = /\/(?:n[0123]|pop)\/(?:s800x800_)?/ig
  if (!size) {
    throw new Error('size has be required')
  }
  if (size.toLowerCase() === 'n0') {
    size = '/mobilecms/s800x800_'
  } else if (size.toLowerCase() === 'n1') {
    size = '/n1/s350x350_'
  } else {
    size = '/' + size + '/'
  }
  return link.replace(reg, size).replace(/http(s)?:/, '')
}

export default {
  install (Vue) {
    Vue.mixin({
      methods: {
        imgSize
      }
    })
  }
}
