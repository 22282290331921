// axios拦截设置
import { AJAX_BASE_URL, getKey, isYQQF, preview, siteConfig } from "@/config";
import axios from "axios";
import { Base64 } from "js-base64";
import { Indicator, Toast } from "mint-ui";
import { Confirm } from "../components/";
import Router from "../router/index";
import Cookie from "@/plugins/cookie"
const { interfaceEncryption = false,  isLogicDot = false } = siteConfig;
const encodeString = (str) => {
  return (
    str.slice(0, 1) +
    Math.random().toString().split(".")[1].slice(0, 3) +
    str.slice(1)
  );
};
const decodeString = (str) => {
  return str.slice(0, 1) + str.slice(4);
};

if (preview.inIframe) {
  axios.defaults.headers.common["real-url"] = AJAX_BASE_URL;
  axios.defaults.baseURL = location.origin + "/proxy-forward";
} else {
  axios.defaults.baseURL = AJAX_BASE_URL;
}
axios.interceptors.request.use((config) => {
  if (process.env.NODE_ENV === "development") {
    config.withCredentials = true;
  }
  axios.xhrNum = axios.xhrNum ? axios.xhrNum + 1 : 1;
  if (config["loading"] !== false) {
    if (window.window.toastHandle) {
      window.toastHandle.close();
    }
    Indicator.open({
      spinnerType: "double-bounce",
    });
  }
  if (!window.cookieEnabled) {
    const address = Cookie.get('address')
    const op = ['goodsPool', 'goods_list', 'get_goods_ById', 'store_goods', 'channelRecommendGoods']
    if (address && op.includes(config.params.op)) {
      config.params.customAddress = address
    }
  }

  if (config.addTime !== false) {
    config.params["_t"] = Date.now();
  }
  // 用户token
  if (!config.params.key && !["goods_list", "store_goods"].includes(config.params.op)) {
    config.params.key = getKey();
  }
  // encrypt_store_id
  if (isYQQF) {
    config.params.encrypt_store_id = window._config.path.replace(/\//g, "");
  }
  // 逻辑积分
  if (isLogicDot) {
    config.params.logicdot = 1
  }
  // 参数加密
  if (interfaceEncryption) {
    const temp = {};
    // get加密参数
    for (let key in config.params) {
      if (config.params[key]) {
        temp[key] = encodeString(
          Base64.encode(encodeURIComponent(config.params[key]))
        );
      }
    }
    config.params = temp;
    // post加密参数
    if (config.data) {
      for (let key of config.data) {
        config.data.set(
          key[0],
          encodeString(Base64.encode(encodeURIComponent(key[1])))
        );
      }
    }
  }

  // 把每个请求的取消句柄挂载到window
  config.cancelToken = new axios.CancelToken((cancel) => {
    if (typeof window.__axiosPromiseArr === "undefined") {
      window.__axiosPromiseArr = [];
    }
    window.__axiosPromiseArr.push({
      cancel: () => {
        axios.xhrNum--;
        cancel();
      },
    });
  });
  return config;
});

axios.interceptors.response.use(
  (response) => {
    axios.xhrNum--;
    if (axios.xhrNum <= 0) {
      Indicator.close();
      axios.xhrNum = 0;
    }
    const data = response.data;
    // 解密结果
    if (interfaceEncryption) {
      data.datas = JSON.parse(Base64.decode(decodeString(data.datas)));
      if (process.env.NODE_ENV !== "production") {
        console.log(data);
      }
    }
    if (data.code === 200) {
      return data;
    }
    const { showMessage = true, showLoginForm = true } = response.config || {};
    if (data.code === 400 && showMessage) {
      // 需要登录，先不做处理
      if (data.login * 1 === 0) {
        if (showLoginForm) {
          // 立即跳转
          if (data.jumpImmediately) {
            if (
              sessionStorage.getItem("dispatchLoginPath") ===
              Router.currentRoute.path
            ) {
              Router.back();
              sessionStorage.removeItem("dispatchLoginPath");
              return;
            }
            if (window._thirdLoginMethods) {
              sessionStorage.setItem(
                "dispatchLoginPath",
                Router.currentRoute.path
              );
              window._thirdLoginMethods();
            } else {
              Router.push({ path: "/login" }).then();
            }
            return;
          }
          Confirm({
            title: "提示",
            message: "您还没有登录，点击确认去登录！",
            callback(res) {
              if (res === "confirm") {
                if (window._thirdLoginMethods) {
                  window._thirdLoginMethods();
                } else {
                  Router.push({ path: "/login" }).then();
                }
              }
            },
          });
        }
      } else {
        const { error, showError = true } = data["datas"];
        if (error && error !== "提交数据错误" && showError) {
          window.toasthandler = Toast({
            message: error,
            position: "bottom",
          });
        }
      }
    }
    return Promise.reject(data);
  },
  (error) => {
    if (/[\u4e00-\u9fa5]+/.test(error.message)) {
      Toast({
        title: error,
        position: "bottom",
      });
    }
  }
);

export { axios };

export default {
  install(Vue) {
    Vue.prototype.$http = axios;
  },
};
