import { EVENTBUS, preview, siteConfig } from "@/config"
import { LOADING, UPDATE_DIRECTION } from "@/store/mutations"
import { wxConfig } from "@/utils/weixin.env"
import Vue from "vue"
import Router from "vue-router"
import TabView from "../pages/TabView"
import store from "../store"

const { international = false } = siteConfig

const originPush = Router.prototype.push
// 覆盖原型链的push
Router.prototype.push = function (location, resolve, reject) {
  // this:路由实例对象

  // 判断用户有没有传后面两个可选参数
  if (resolve || reject) {
    return originPush.call(this, location, resolve, reject)
  }
  /*
  默认底层： catch()方法代码  throw err : 抛出异常
  吞掉报错原理： 重写catch()方法,把默认底层的 throw err给去掉，就不会抛出异常
  */
  return originPush.call(this, location).catch((err) => {})
}

Vue.use(Router)

// 应用生产环境路径处理
let basePath
if (preview.inIframe || process.env.NODE_ENV !== "production") {
  basePath = "/"
} else {
  const siteArea = window._config.domain.replace(
    /^https?:\/\/[\w-]+(\.[\w-]+)+(:\d+)?/g,
    ""
  )
  basePath = siteArea + window._config.path
}

export const base = basePath + "dist/"

const router = new Router({
  mode: "history",
  base,
  scrollBehavior (to, from, savedPosition) {
    // 异步滚动操作
    if (savedPosition) {
      return new Promise((resolve) => {
        EVENTBUS.$on("routeScrollBehavior", () => {
          resolve(savedPosition)
        })
      }).catch((e) => e)
    }
    return new Promise((resolve) => {
      EVENTBUS.$on("routeScrollBehavior", () => {
        resolve({ x: 0, y: 1 })
      })
    })
  },
  routes: [
    {
      path: "*",
      component: () => import("@/pages/404")
    },
    {
      path: "/",
      component: TabView,
      children: [
        {
          path: "wap/login.html",
          redirect: "index.html"
        },
        {
          path: "",
          redirect: "index.html"
        },
        {
          path: "index.html",
          name: "index.html",
          component: () => import("@/pages/Home")
        },
        {
          path: "/channel",
          name: "channel",
          component: () => import("@/pages/Channel")
        },
        {
          path: "menu",
          name: "menu",
          component: () => import("@/pages/Menu")
        },
        {
          path: "cart",
          name: "cart",
          component: () => import("@/pages/Cart")
        },
        {
          path: "order",
          name: "order",
          component: () => import("@/pages/Order")
        },
        {
          path: "/member",
          name: "member",
          component: () => import("@/pages/Member")
        },
        {
          path: "/brand/list",
          name: "brand/list",
          component: () => import("@/pages/Default")
        },
        {
          path: "/brands",
          name: "brands",
          component: () => import("@/pages/Brand")
        },
        {
          path: "/venue/recommend",
          name: "venue/recommend",
          component: () => import("@/components/Venue")
        },
        {
          path: "/brands-new",
          name: "brands-new",
          component: () => import("@/pages/BrandDouLi")
        }
      ]
    },
    {
      path: "/search/input",
      name: "search/input",
      component: () => import("@/pages/Search/Input"),
      beforeEnter: (to, from, next) => {
        let direction = ""
        store.commit(UPDATE_DIRECTION, { direction })
        next()
      }
    },
    {
      path: "/search/list",
      name: "search/list",
      component: () => import("@/pages/Search/List")
    },
    {
      path: "/wap(/)?/tmpl/product_detail.html", // 兼容旧版详情页面
      redirect: "/detail"
    },
    {
      path: "/detail",
      name: "detail",
      component: () => import("@/pages/Detail"),
      beforeEnter: (to, from, next) => {
        let direction
        if (from.name === "content") {
          direction = "slide-down"
        } else {
          if (to.query._t > from.query._t) {
            direction = "pop-in"
          } else {
            direction = "pop-out"
          }
        }
        store.commit(UPDATE_DIRECTION, { direction })
        next()
      }
    },
    {
      path: "/make-order",
      name: "make-order",
      component: () => import("@/pages/MakeOrder")
    },
    {
      path: "/order-detail",
      name: "order-detail",
      component: () => import("@/pages/OrderDetail")
    },
    {
      path: "/address/list",
      name: "address/list",
      component: () => import("@/pages/Address/List")
    },
    {
      path: "/address/edit",
      name: "address/edit",
      component: () => import("@/pages/Address/Edit")
    },
    {
      path: "/store",
      name: "store",
      component: () => import("@/pages/Store")
    },
    {
      path: "/express",
      name: "express",
      component: () => import("@/pages/Express")
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import("@/pages/Login" + (international ? "ByEmail" : ""))
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/pages/Register")
    },
    {
      path: "/find-password",
      name: "find-password",
      component: () => import("@/pages/FindPassword")
    },
    {
      path: "/content",
      name: "content",
      component: () => import("@/pages/Content"),
      beforeEnter: (to, from, next) => {
        if (from.name === "detail") {
          store.commit(UPDATE_DIRECTION, { direction: "slide-up" })
        }
        next()
      }
    },
    {
      path: "/article",
      name: "article",
      component: () => import("@/pages/Article")
    },
    {
      path: "/protocol",
      name: "protocol",
      component: () => import("@/pages/Protocol")
    },
    {
      path: "/service",
      name: "service",
      component: () => import("@/pages/Service/List")
    },
    {
      path: "/service/detail",
      name: "service/detail",
      component: () => import("@/pages/Service/Detail")
    },
    {
      path: "/service/return",
      name: "service/return",
      component: () => import("@/pages/Service/Return")
    },
    {
      path: "/service/express",
      name: "service/express",
      component: () => import("@/pages/Service/Express")
    },
    {
      path: "/service/Refund",
      name: "service/Refund",
      component: () => import("@/pages/Service/Refund")
    },
    {
      path: "/collect",
      name: "collect",
      component: () => import("@/pages/Collect")
    },
    {
      path: "/yue",
      name: "yue",
      component: () => import("@/pages/Yue")
    },
    {
      path: "/special",
      name: "special",
      alias: "/wap/special.html",
      component: () => import("@/pages/Default")
    },
    {
      path: "/brand/index",
      name: "brand/index",
      component: () => import("@/pages/Brand/Index")
    },
    {
      path: "/brand/detail",
      name: "brand/detail",
      component: () => import("@/pages/Brand/Detail")
    },
    {
      path: "/store.html",
      redirect: "/brand/detail"
    },
    {
      path: "/limit",
      name: "limit",
      component: () => import("@/pages/Limit")
    },
    {
      path: "/evaluation",
      name: "evaluation",
      component: () => import("@/pages/Evaluation")
    },
    {
      path: "/redpack/list",
      name: "redpack/list",
      component: () => import("@/pages/Redpack/List")
    },
    {
      path: "/redpack/own",
      name: "redpack/name",
      component: () => import("@/pages/Redpack/Own")
    },
    {
      path: "/redpack/exchange",
      name: "redpack/exchange",
      component: () => import("@/pages/Redpack/Exchange")
    },
    {
      path: "/voucher",
      name: "voucher",
      component: () => import("@/pages/Voucher")
    },
    {
      path: "/voucher-get",
      name: "voucher-get",
      component: () => import("@/pages/VoucherGet")
    },
    {
      path: "/voucher-list",
      name: "voucher-list",
      component: () => import("@/pages/VoucherList")
    },
    {
      path: "/pin/explain",
      name: "pin/explain",
      component: () => import("@/pages/Pin/Explain")
    },
    {
      path: "/pin/join",
      name: "ping/join",
      component: () => import("@/pages/Pin/Join")
    },
    {
      path: "/pin/list",
      name: "ping/list",
      component: () => import("@/pages/Pin/List")
    },
    {
      path: "/recharge/phone",
      name: "recharge/phone",
      component: () => import("@/pages/Recharge/Phone")
    },
    {
      path: "/make-vr-order",
      name: "make-vr-order",
      component: () => import("@/pages/MakeVrOrder")
    },
    {
      path: "/order-vr",
      name: "order-vr",
      component: () => import("@/pages/OrderVr")
    },
    {
      path: "/order-vr-detail",
      name: "order-vr-detail",
      component: () => import("@/pages/OrderVrDetail")
    },
    {
      path: "/eval-list",
      name: "eval-list",
      component: () => import("@/pages/EvalList")
    },
    {
      path: "/movie",
      name: "movie",
      component: () => import("@/pages/Movie/Index")
    },
    {
      path: "/movie/pay",
      name: "movie/pay",
      component: () => import("@/pages/Movie/Pay")
    },
    {
      path: "/security",
      name: "security",
      component: () => import("@/pages/Security")
    },
    {
      path: "/card/gift/convert",
      name: "card/gift/convert",
      component: () => import("@/pages/Card/GiftConvert")
    },
    {
      path: "/card/gift/list",
      name: "card/gift/list",
      component: () => import("@/pages/Card/GiftList")
    },
    {
      path: "/venue",
      name: "venue",
      component: () => import("@/pages/Venue")
    },
    {
      path: "/service/upload",
      name: "service/upload",
      component: () => import("@/pages/Service/Upload")
    },
    {
      path: "/plan/limit-redpacket",
      name: "plan/limit-redpacket",
      component: () => import("@/pages/Plan/LimitRedPacket")
    },
    {
      path: "/combine/list",
      name: "combine/list",
      component: () => import("@/pages/CombineGoods/Index")
    },
    {
      path: "/combine/detail",
      name: "combine/detail",
      component: () => import("@/pages/CombineGoods/Detail")
    },
    {
      path: "/comment",
      name: "comment",
      component: () => import("@/pages/Comment/Index")
    },
    {
      path: "/sign-in",
      name: "sign-in",
      component: () => import("@/pages/SignIn")
    },
    {
      path: "/union/pay",
      name: "union/pay",
      component: () => import("@/pages/Union/Pay")
    },
    {
      path: "/union/fandq",
      name: "union/fandq",
      component: () => import("@/pages/Union/FandQ")
    },
    {
      path: "/zizhi",
      name: "zizhi",
      component: () => import("@/pages/ZiZhi")
    },
    {
      path: "/voucher-relay",
      name: "voucher-relay",
      component: () => import("@/pages/VoucherRelay")
    },
    {
      path: "/fudou/index",
      name: "fudou/index",
      component: () => import("@/pages/Fudou/Index")
    },
    {
      path: "/h5pay",
      name: "h5pay",
      component: () => import("@/pages/H5Pay/Pay.vue")
    },
    {
      path: "/chat",
      name: "chat",
      component: () => import("@/pages/Chat.vue")
    },
    {
      path: "/dangqi",
      name: "dangqi",
      component: () => import("@/pages/DangQi/Index.vue")
    }, {
      path: "/medicine/index",
      name: "medicine/index",
      component: () => import("@/pages/Medicine/Index.vue")
    }, {
      path: "/medicine/person",
      name: "medicine/person",
      component: () => import("@/pages/Medicine/Person.vue")
    }, {
      path: "/medicine/hospital",
      name: "medicine/hospital",
      component: () => import("@/pages/Medicine/Hospital.vue")
    }, {
      path: "/medicine/datetime",
      name: "medicine/datetime",
      component: () => import("@/pages/Medicine/Datetime.vue")
    }, {
      path: "/medicine/appointment",
      name: "medicine/appointment",
      component: () => import("@/pages/Medicine/Appointment.vue")
    }, {
      path: "/medicine/result",
      name: "medicine/result",
      component: () => import("@/pages/Medicine/Result.vue")
    }, {
      path: "/medicine/record",
      name: "medicine/record",
      component: () => import("@/pages/Medicine/Record.vue")
    }, {
      path: "/qrcode-history",
      name: "qrcode-history",
      component: () => import("@/pages/QrcodeHistory.vue")
    }, {
      path: "/bake/",
      component: () => import("@/pages/Bake/Geolocation.vue"),
      children: [{
        path: "index",
        name: "bake/index",
        component: () => import("@/pages/Bake/Index.vue")
      }, {
        path: "store",
        name: "bake/store",
        component: () => import("@/pages/Bake/Store.vue")
      },
        {
          path: "user",
          name: "bake/user",
          component: () => import("@/pages/Member")
        }, {
          path: "order",
          name: "bake/order",
          component: () => import("@/pages/Order")
        }]
    },
    {
      path: "/turntable/index",
      name: "turntable/index",
      component: () => import("@/pages/Extension/Turntable/Index.vue")
    }, {
      path: "/turntable/list",
      name: "turntable/list",
      component: () => import("@/pages/Extension/Turntable/List.vue")
    },
    {
      path: "/qingmei/location",
      name: "qingmei/location",
      component: () => import("@/pages/QingMei/Location.vue")
    },
    {
      path: "/qingmei/location/search",
      name: "qingmei/location/search",
      component: () => import("@/pages/QingMei/LocationSearch.vue")
    }
  ]
})

const isEqualRoute = (to, from) => {
  let isEqual = false
  // 当手动设置_replace时候认为是replace操作
  if (to.query._replace === true || to.query._replace === "true") {
    isEqual = true
  }
  if (to.name === from.name) {
    const reg = /([&?])_t=[^&]+/
    if (to.fullPath.replace(reg, "") === from.fullPath.replace(reg, "")) {
      isEqual = true
    }
  }
  return isEqual
}

router.beforeEach((to, from, next) => {
  // 预览参数
  if (process.env.NODE_ENV === "development") {
    if (!to.query.preview) {
      to.query.preview = from.query.preview
      to.query.templateId = from.query.templateId
      to.query.templateType = from.query.templateType
    }
  }
  // 跳转参数
  let temp = {
    ...to,
    query: {
      ...to.query,
      _t: Date.now()
    }
  }
  let direction
  // 前往的链接不存在time参数（正常跳转打开新页面）
  if (!to.query._t) {
    // 不存在来路链接别名（直接打开网址情况） 或者  前往的链接 === 来路链接
    if (!from.name || isEqualRoute(to, from)) {
      temp.replace = true
      delete temp.query._replace
    }
    // 更新direction状态
    direction = "pop-in"
    // 前往链接存在time参数（浏览器级别前进或后退）
  } else {
    temp = {}
    // 这里暂不考虑当前页面无限跳转（前往链接===来路链接） to.name === from.name
    // 来路链接的时间戳 > 前往链接的时间戳 说明是在后退
    if (from.query._t > to.query._t) {
      direction = "pop-out"
    } else {
      direction = "pop-in"
    }
    // 页面统计
    // 放在这里做优化处理 之前一个页面会统计两次
    if (process.env.NODE_ENV === "production") {
      let url
      if (to.name === "index.html" && to.query.id) {
        url =
          to.path +
          "?id=" +
          to.query.id +
          (to.query._t ? "&_t=" + to.query._t : "")
      } else {
        url = to.fullPath
      }
      window._hmt = window._hmt || []
      window._hmt.push(["_trackPageview", base + url.slice(1)])
    }
    // 页面切换通知
    wxConfig(to)
    // 全局通知
    if (window._urlChange) {
      window._urlChange(to)
    }
  }
  store.commit(UPDATE_DIRECTION, { direction, normal: direction })

  // 取消上一页面的请求
  if (window.__axiosPromiseArr) {
    window.__axiosPromiseArr.forEach((val, i) => {
      val.cancel()
      delete window.__axiosPromiseArr[i]
    })
  }
  // page loading flag
  store.commit(LOADING, true)
  next(temp)
})

router.afterEach(() => {
  // Indicator.close()
  store.commit(LOADING, false)
})

router.onError((error) => {
  const pattern = /Loading chunk \d+(-legacy)? failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  // const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    // router.replace(targetPath)
    location.reload(true)
  }
})

export default router
